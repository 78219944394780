/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * Generated with the TypeScript template
 * https://github.com/react-native-community/react-native-template-typescript
 *
 * @format
 */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { StatusBar, Platform, Alert } from "react-native";
import {
	NavigationContainer,
	DefaultTheme,
	DarkTheme,
	useNavigationContainerRef,
} from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { useSelector, useDispatch } from "react-redux";
import { switchThemeAction } from "common/src/store/theme/themeActions";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SplashScreen from "react-native-splash-screen";
let analytics, crashlytics;
if (Platform.OS !== 'web') {
  // Firebase imports and initializations are only done for native platforms
  analytics = require('@react-native-firebase/analytics').default;
  crashlytics = require('@react-native-firebase/crashlytics').default;
}
import { version } from "../package.json";
import "common/src/services/localization";
import { useTranslation } from "react-i18next";
import { NavTypes } from "common/src/services/navigationEmitter/navigationEmitter";
import {
	resetSuccessModalAction,
	resetErrorModalAction,
	logOutAction,
	getMeAction,
	getDataSharingAction,
	getNotificationsAction,
} from "common/src/store/actions/authenticationActions";
import { sendFbTokenAction } from "common/src/store/FB-token/fb_tokenAction";
import { biometricKeyExistsAction } from "common/src/store/biometrics/biometricsActions";
import ReactNativeBiometrics from "react-native-biometrics";
import GlobalStyles from "common/src/styles/GlobalStyles";
import CommonModal from "common/src/components/CommonModal";
import Tutorial from "./modules/Tutorial";
import Mini_tutorial from "./modules/Tutorial";
import NeurologistStep from "./modules/NeurologistStep";
import Welcome from "./modules/Welcome";
import Login from "./modules/Login";
import Register from "./modules/Register";
import VerifyEmail from "./modules/VerifyEmail";
import OnboardingQuestions from "./modules/OnboardingQuestions";
import PrivacyConsent from "./modules/PrivacyConsent";
import InformedConsent from "./modules/PrivacyConsent";
import Loading from "common/src/components/Loading";
import TabNavigation from "./modules/TabNavigation";
import Settings from "./modules/Settings";
import PasswordForgotten from "./modules/PasswordForgotten";
import About from "./modules/About";
import AddEvent from "./modules/AddEvent";
import Profile from "./modules/Profile";
import ChangeMyPdw from "./modules/ChangeMyPwd";
import Address from "./modules/Address";
import My_neurologists from "./modules/My_neurologists";
import AddNeurologist from "./modules/AddNeurologist";
import Notifications from "./modules/Notifications";
import Timezone from "common/src/components/Timezone";
import MoodSleepNotifications from "common/src/components/MoodAndSleepNotifications";
import Studies from "./modules/Studies";
import StudyOverview from "common/src/components/CommonStudyOverview";
import StudySection from "common/src/components/CommonStudySection";
import StudyForm from "common/src/components/CommonStudyForm";
import CommonStudyDisclaimer from "common/src/components/CommonStudyDisclaimer";
import Treatments from "./modules/Treatments";
import AddMedication from "./modules/AddMedication/AddMedication";
import AddTreatments from "./modules/AddMedication/AddTreatments";
import Drugs from "./modules/AddMedication/Drugs";
import MedicationHistory from "./modules/EditMedication/MedicationHistory";
import EditMedication from "./modules/EditMedication/EditMedication";
import ReminderView from "./modules/ReminderView";
import OnboardingAddMedication from "./modules/OnboardingAddMedication";
import OnboardingAddHeadachType from "./modules/OnboardingAddHeadachType";
import MFAValidation from "./modules/MfaAuth/MFAValidation";
import LoginWithBiometrics from "./modules/MfaAuth/LoginWithBiometrics";
import EnableDisableAuth from "./modules/MfaAuth/EnableDisableAuth";
import MoreGraphs from "./modules/MoreGraphs";
import AddRescueMedication from "./modules/AddMedication/AddRescueMedication";
import AddEmergencyMedication from "./modules/AddMedication/AddEmergencyMedication";
import SelectRescueMedication from "./modules/AddMedication/SelectRescueMedication";
import AddHeadacheTypes from "./modules/HeadacheTypes/AddHeadacheTypes";
import HeadacheTypesList from "./modules/HeadacheTypes/HeadacheTypesList";
import PatientOrganisation from "./modules/PatientOrganisation";
import { RootState } from "common/src/store/reducers";
import { useNetInfo } from "@react-native-community/netinfo";
import DataSharing from "./modules/DataSharing";
import Azmm from "./modules/Azmm";
import {
	IS_ANALYTICS_ENABLED,
	IS_CRASH_REPORTS_ENABLED,
} from "common/src/env.json";
import notificationEmitter, {
	NotificationTypes,
} from "common/src/notifEmitter/notifEmitter";
import CommonErrorBoundary from "common/src/components/CommonErrorBoundary";

const Stack = createStackNavigator();

const rnBiometrics = new ReactNativeBiometrics({
	allowDeviceCredentials: true,
});

const config = {
	screens: {
		Tutorial: "Tutorial",
		Mini_tutorial: "Mini_tutorial",
		Welcome: "Welcome",
		Login: "Login",
		Register: "Register",
		NeurologistStep: "NeurologistStep",
		Settings: "Settings",
		About: "About",
		My_neurologists: "My_neurologists",
		AddNeurologist: "AddNeurologist",
		AddEvent: "AddEvent",
		Address: "Address",
		Notifications: "Notifications",
		Timezone: "Timezone",
		MoodSleepNotifications: "MoodSleepNotifications",
		ReminderView: "ReminderView",
		OnboardingAddMedication: "OnboardingAddMedication",
		OnboardingAddHeadachType: "OnboardingAddHeadachType",
		DataSharingRequest: "DataSharingRequest",
		MFAValidation: "MFAValidation",
		EnableDisableAuth: "EnableDisableAuth",
		LoginWithBiometrics: "LoginWithBiometrics",
		AddRescueMedication: "AddRescueMedication",
		AddEmergencyMedication: "AddEmergencyMedication",
		SelectRescueMedication: "SelectRescueMedication",
		AddHeadacheTypes: "AddHeadacheTypes",
		HeadacheTypesList: "HeadacheTypesList",
		Studies: "Studies",
		CommonStudyDisclaimer: "CommonStudyDisclaimer",
		PatientOrganisation: "PatientOrganisation",
		Azmm: "Azmm",
		Main: {
			screens: {
				Home: "Home",
				Diary: "Diary",
				Treatments: "Treatment",
				Menu: "Menu",
			},
		},
	},
};
const linking = {
	prefixes: [""],
	config,
};
const App = ({ FBtoken, OnPushNotifClicked }) => {
	const navigationRef = useNavigationContainerRef();
	const route = useRef();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const Me = useSelector((state: any) => state.getMe.response);
	const GetMeConsent = useSelector((state: any) => state.getMe.consent);
	const GetIsEmailValidated = useSelector(
		(state: any) => state.getMe.isEmailValidated
	);
	const GetMeOnBoarding = useSelector((state: any) => state.getMe.onBoarding);
	const GetMeSettings = useSelector((state: any) => state.getMe.settings);
	const AllSet = useSelector((state: any) => state.allSetReducer.all_set);
	const ThemeMode = useSelector(
		(state: RootState) => state.themeReducer.themeMode
	);
	const errorMessages = useSelector((state: any) => state.modal.errors);
	const successMessages = useSelector((state: any) => state.modal.success);
	const isBiometricKeyExists = useSelector(
		(state: RootState) =>
			state.biometricKeyExistsReducer.isBiometricKeyExists
	);
	const [LoadingRes, setLoadingRes] = useState(true);
	const [isAllSet, setAllSet] = useState<boolean>(false);
	const [stackState, setStackState] = useState<string>("notStack");
	const [resultStorage, setResultStorage] =
		useState<Record<string, string | null>>();
	const [registrationPageName, setRegistrationPageName] =
		useState<string>("");
	const [registartionPageNotDone, setRegistrationPageNotDone] =
		useState<boolean>(false);
	const netInfo = useNetInfo();
	const [FBToken, setFBToken] = useState<string>("");
	const [deviceType, setDeviceType] = useState<string>("");

	useEffect(() => {
		if (Platform.OS !== "web") {
			SplashScreen.hide();
		}
		setLoadingRes(false);
	}, []);

	useEffect(() => {
		if (stackState === NavTypes.Completed) {
			OnPushNotifClicked &&
				OnPushNotifClicked({ navigation: navigationRef.current });

			notificationEmitter.on(NotificationTypes.All, () => {
				dispatch(getNotificationsAction({}));
			});
		}

		notificationEmitter.on(NotificationTypes.MFA_notif, (data) => {
			Alert.alert(
				t("translation:MFA.otp.alert.title"),
				t("translation:MFA.otp.alert.body", { otpCode: data.text }),
				[
					{
						text: t("translation:modal.btn.close"),
					},
				]
			);
		});
	}, [stackState]);

	const getAppTheme = useCallback(async () => {
		await AsyncStorage.getItem("theme").then((value: any) => {
			if (value !== null && value !== "") {
				dispatch(
					switchThemeAction({
						themeMode: value,
					})
				);
			} else {
				dispatch(
					switchThemeAction({
						themeMode: "light",
					})
				);
			}
		});
	}, []);

	useEffect(() => {
		if (Platform.OS !== "web") {
			const enableDisableAnalyticsCrashes = async () => {
				await analytics().setAnalyticsCollectionEnabled(IS_ANALYTICS_ENABLED);
        		await crashlytics().setCrashlyticsCollectionEnabled(IS_CRASH_REPORTS_ENABLED);
			};
			enableDisableAnalyticsCrashes();
			biometricKey();
		}
	}, []);

	useEffect(() => {
		getAppTheme();
	}, [getAppTheme]);

	useEffect(() => {
		setAllSet(AllSet);
	}, [AllSet]);

	useEffect(() => {
		getAllKeys();
	}, [resultStorage, GetMeConsent, Me, GetMeOnBoarding]);

	const getAllKeys = async () => {
		const result: any = {};
		const keys = await AsyncStorage.getAllKeys();
		let update = false;
		for (const key of keys) {
			const val = await AsyncStorage.getItem(key);
			if (!resultStorage || resultStorage[key] !== val) {
				update = true;
				if (resultStorage && resultStorage.token) {
					resultStorage.token = val;
				}
			}
			result[key] = val;
		}

		if (update) setResultStorage(result);

		if (resultStorage) {
			if (
				resultStorage.tutorialIsDone === null ||
				resultStorage.tutorialIsDone === "" ||
				resultStorage.tutorialIsDone === undefined
			) {
				setStackState(NavTypes.Tutorial);
				setLoadingRes(false);
			} else if (!resultStorage.token) {
				setStackState(NavTypes.NotCompleted);
				setLoadingRes(false);
			} else if (resultStorage.token && resultStorage.token !== "") {
				if (netInfo.isConnected && !Me) {
					if (!LoadingRes) setLoadingRes(true);
					dispatch(
						getMeAction({
							callback: () => {
								setLoadingRes(false);
							},
							errorCallback: () => {
								setLoadingRes(false);
								setRegistrationPageName("");
								setStackState(NavTypes.NotCompleted);
							},
						})
					);
				} else if (Me) {
					if (GetIsEmailValidated === false) {
						setRegistrationPageName("VerifyEmail");
						setRegistrationPageNotDone(true);
						setStackState(NavTypes.NotCompleted);
					} else if (
						(!GetMeOnBoarding || !GetMeOnBoarding?.endOnboarding) &&
						Me.patientData?.azmm &&
						Me.patientData?.azmmValidation === false
					) {
						setRegistrationPageName("Azmm");
						setRegistrationPageNotDone(true);
						setStackState(NavTypes.NotCompleted);
					} else if (
						!GetMeOnBoarding ||
						(!GetMeOnBoarding?.endOnboarding &&
							!GetMeOnBoarding?.neurologistDone)
					) {
						dispatch(
							getDataSharingAction({
								callback: (result: any) => {
									if (result?.length > 0) {
										setRegistrationPageName(
											"DataSharingRequest"
										);
										setRegistrationPageNotDone(true);
										setStackState(NavTypes.NotCompleted);
									} else {
										setRegistrationPageName(
											"NeurologistStep"
										);
										setRegistrationPageNotDone(true);
										setStackState(NavTypes.NotCompleted);
									}
								},
							})
						);
					} else if (!GetMeConsent) {
						setRegistrationPageName("PrivacyConsent");
						setRegistrationPageNotDone(true);
						setStackState(NavTypes.NotCompleted);
					} else if (
						GetMeOnBoarding &&
						!GetMeOnBoarding?.endOnboarding &&
						!GetMeOnBoarding?.questionsDone
					) {
						setRegistrationPageName("OnboardingQuestions");
						setRegistrationPageNotDone(true);
						setStackState(NavTypes.NotCompleted);
					} else if (
						GetMeOnBoarding &&
						GetMeConsent &&
						GetMeOnBoarding?.endOnboarding
					) {
						setRegistrationPageNotDone(false);
						setRegistrationPageName("");
						setStackState(NavTypes.Completed);
						sendFBToken();
					}
				} else {
					setRegistrationPageName("");
					setStackState(NavTypes.NotCompleted);
				}
			}
		}
	};

	const closeSuccessModal = () => {
		dispatch(resetSuccessModalAction({ message: successMessages[0] }));
	};

	const closeErrorModal = () => {
		if (errorMessages[0] === "expired_token") {
			setStackState(NavTypes.NotCompleted);
			dispatch(logOutAction());
		}
		dispatch(resetErrorModalAction({ message: errorMessages[0] }));
	};

	const errorModalBtn = [
		{
			type: "primary",
			title: t("translation:modal.btn.close"),
			click: closeErrorModal,
		},
	];

	const successModalBtn = [
		{
			type: "primary",
			title: t("translation:modal.btn.close"),
			click: closeSuccessModal,
		},
	];

	const checkIfMsgIsTranslated = (msg) => {
		if (msg.includes("translation:")) return false;
		else return true;
	};

	const sendFBToken = async () => {
		const token = await FBtoken();
		const device = Platform.OS === 'android' ? 'android' : Platform.OS === 'ios' ? 'ios' : '';

		setFBToken(token);
		if (Platform.OS === 'android')
		setDeviceType('android')
		else if (Platform.OS === 'ios')
		setDeviceType('ios')

		let dataToSend = {
			data: {
				settings: {
					device_type: device,
					device_token: token
				},
			},
		};

		if (Platform.OS !== 'web' && !findMyFBTokenDevice(device, token) && netInfo.isConnected) {
			dispatch(sendFbTokenAction(dataToSend));
		}
	};

	const findMyFBTokenDevice = (device, fbtoken) => {
		return GetMeSettings.devices[device].find(token => token === fbtoken)
	}

	const biometricKey = async () => {
		await rnBiometrics.biometricKeysExist().then((resultObject) => {
			const { keysExist } = resultObject;

			if (keysExist) {
				dispatch(biometricKeyExistsAction({ key: true }));
			}
		});
	};

	const initialState = {
		index: 0,
		routes: [
			{
				name: "Main",
			},
		],
	};
	if (LoadingRes) return <Loading netConnected={true} />;
	else
		return (
			<NavigationContainer
				ref={navigationRef}
				linking={linking}
				initialState={initialState}
				theme={
					ThemeMode === "light"
						? {
								...DefaultTheme,
								colors: {
									...DefaultTheme.colors,
									...GlobalStyles.colors.migraine_light,
								},
						  }
						: {
								...DarkTheme,
								colors: {
									...DarkTheme.colors,
									...GlobalStyles.colors.migraine_dark,
								},
						  }
				}
				onReady={() => {
					// @ts-ignore
					route.current = navigationRef?.getCurrentRoute()?.name;
				}}
				onStateChange={async () => {
					const previousRouteName = route.current;
					const currentRouteName =
						navigationRef?.getCurrentRoute()?.name;
					if (
						previousRouteName &&
						currentRouteName &&
						previousRouteName !== currentRouteName &&
						Platform.OS !== "web"
					){
						await analytics().logScreenView({
							screen_name: currentRouteName,
							screen_class: currentRouteName,
						});
						await analytics().logEvent(currentRouteName, {
							previous_screen: previousRouteName,
						});
					}
					// Save the current route name for later comparison
					// @ts-ignore
					route.current = currentRouteName;
				}}
			>
				<StatusBar
					barStyle={
						ThemeMode === "light" ? "dark-content" : "light-content"
					}
					backgroundColor={ThemeMode === "light" ? "#fff" : "#202224"}
				/>
				{!!errorMessages?.length && (
					<CommonModal
						testID="errorModal"
						type={"error"}
						title={t("translation:modal.titles.error")}
						text={
							t(
								errorMessages[0] === "expired_token"
									? "translation:errors.expired_token"
									: `translation:${errorMessages[0]}`
							) || errorMessages[0]
						}
						buttons={errorModalBtn}
						onClose={closeErrorModal}
					/>
				)}
				{!!successMessages?.length && (
					<CommonModal
						testID="successModal"
						type={"info"}
						title={t("translation:modal.titles.success")}
						text={
							checkIfMsgIsTranslated(successMessages[0])
								? successMessages[0]
								: t(successMessages[0])
						}
						buttons={successModalBtn}
						onClose={closeSuccessModal}
					/>
				)}
				<CommonErrorBoundary
					navigation={route}
					user_id={Me?.result?.id}
					version={version}
				>
					<Stack.Navigator
						screenOptions={{
							headerShown: false,
							title: "MigraineManager",
						}}
					>
						{stackState === NavTypes.Tutorial ? (
							<>
								<Stack.Screen
									name="Tutorial"
									component={Tutorial}
									initialParams={{ param: false }}
								/>
								<Stack.Screen
									name="Welcome"
									component={Welcome}
								/>
								<Stack.Screen name="Login" component={Login} />
								<Stack.Screen
									name="MFAValidation"
									component={MFAValidation}
								/>
								<Stack.Screen
									name="Register"
									component={Register}
								/>
								<Stack.Screen
									name="PasswordForgotten"
									component={PasswordForgotten}
								/>
								<Stack.Screen
									name="NeurologistStep"
									component={NeurologistStep}
								/>
								<Stack.Screen
									name="Settings"
									component={Settings}
								/>
								<Stack.Screen name="About" component={About} />
							</>
						) : stackState === NavTypes.NotCompleted ? (
							<>
								<Stack.Screen name="Login" component={Login} />
								<Stack.Screen
									name="MFAValidation"
									component={MFAValidation}
								/>
								<Stack.Screen
									name="Register"
									component={Register}
								/>
								<Stack.Screen
									name="PasswordForgotten"
									component={PasswordForgotten}
								/>
								<Stack.Screen
									name="Settings"
									component={Settings}
								/>
								<Stack.Screen name="About" component={About} />
								{/** Register */}
								{registartionPageNotDone === true ? (
									<>
										<Stack.Screen
											name={registrationPageName}
											component={
												registrationPageName ===
												"PrivacyConsent"
													? PrivacyConsent
													: registrationPageName ===
													  "VerifyEmail"
													? VerifyEmail
													: registrationPageName ===
													  "Azmm"
													? Azmm
													: registrationPageName ===
													  "DataSharingRequest"
													? DataSharing
													: OnboardingQuestions
											}
											initialParams={{ param: false }}
										/>

										<Stack.Screen
											name="Welcome"
											component={Welcome}
										/>
									</>
								) : (
									<>
										<Stack.Screen
											name="Welcome"
											component={Welcome}
										/>
										{registrationPageName !== "" && (
											<Stack.Screen
												name={registrationPageName}
												component={
													registrationPageName ===
													"PrivacyConsent"
														? PrivacyConsent
														: registrationPageName ===
														  "VerifyEmail"
														? VerifyEmail
														: registrationPageName ===
														  "Azmm"
														? Azmm
														: registrationPageName ===
														  "DataSharingRequest"
														? DataSharing
														: OnboardingQuestions
												}
												initialParams={{ param: false }}
											/>
										)}
									</>
								)}
								{registrationPageName !== "Azmm" && (
									<Stack.Screen
										name="Azmm"
										component={Azmm}
									/>
								)}
								{registrationPageName !== "NeurologistStep" && (
									<Stack.Screen
										name="NeurologistStep"
										component={NeurologistStep}
									/>
								)}
								{registrationPageName !==
									"OnboardingQuestions" && (
									<Stack.Screen
										name="OnboardingQuestions"
										component={OnboardingQuestions}
									/>
								)}
								{registrationPageName !== "PrivacyConsent" && (
									<Stack.Screen
										name="PrivacyConsent"
										component={PrivacyConsent}
										initialParams={{ param: false }}
									/>
								)}
								{registrationPageName !== "VerifyEmail" && (
									<Stack.Screen
										name="VerifyEmail"
										component={VerifyEmail}
									/>
								)}
								{registrationPageName !==
									"DataSharingRequest" && (
									<Stack.Screen
										name="DataSharingRequest"
										component={DataSharing}
									/>
								)}
								<Stack.Screen
									name="Tutorial"
									component={Tutorial}
									initialParams={{ param: true }}
								/>
							</>
						) : stackState === NavTypes.Completed ? (
							<>
								{isAllSet ? (
									<>
										<Stack.Screen
											name="OnboardingAddHeadachType"
											component={OnboardingAddHeadachType}
										/>
										<Stack.Screen
											name="OnboardingAddMedication"
											component={OnboardingAddMedication}
										/>
										<Stack.Screen
											name="Main"
											component={TabNavigation}
											initialParams={{
												deviceType,
												FBToken,
											}}
										/>
									</>
								) : (
									<>
										{isBiometricKeyExists &&
											Platform.OS !== "web" && (
												<Stack.Screen
													name="LoginWithBiometrics"
													component={
														LoginWithBiometrics
													}
													options={{
														gestureEnabled: false,
													}}
												/>
											)}
										<Stack.Screen
											name="Main"
											component={TabNavigation}
											initialParams={{
												deviceType,
												FBToken,
											}}
										/>
										<Stack.Screen
											name="OnboardingAddHeadachType"
											component={OnboardingAddHeadachType}
										/>
										<Stack.Screen
											name="OnboardingAddMedication"
											component={OnboardingAddMedication}
										/>
									</>
								)}
								<Stack.Screen
									name="AddEvent"
									component={AddEvent}
								/>
								<Stack.Screen
									name="Settings"
									component={Settings}
								/>
								<Stack.Screen name="About" component={About} />
								<Stack.Screen
									name="Profile"
									component={Profile}
								/>
								<Stack.Screen
									name="Address"
									component={Address}
								/>
								<Stack.Screen
									name="My_neurologists"
									component={My_neurologists}
								/>
								<Stack.Screen
									name="AddNeurologist"
									component={AddNeurologist}
								/>
								<Stack.Screen name="Azmm" component={Azmm} />
								<Stack.Screen
									name="Notifications"
									component={Notifications}
								/>
								<Stack.Screen
									name="Timezone"
									component={Timezone}
								/>
								<Stack.Screen
									name="MoodSleepNotifications"
									component={MoodSleepNotifications}
								/>
								<Stack.Screen
									name="ChangeMyPdw"
									component={ChangeMyPdw}
								/>
								<Stack.Screen
									name="InformedConsent"
									component={InformedConsent}
									initialParams={{ param: true }}
								/>
								<Stack.Screen
									name="EnableDisableAuth"
									component={EnableDisableAuth}
									initialParams={{ FBToken }}
								/>
								<Stack.Screen
									name="Mini_tutorial"
									component={Mini_tutorial}
									initialParams={{ param: true }}
								/>
								<Stack.Screen
									name="PatientOrganisation"
									component={PatientOrganisation}
								/>
								{/** Study routes */}
								<Stack.Screen
									name="Studies"
									component={Studies}
								/>
								<Stack.Screen
									name="StudyOverview"
									component={StudyOverview}
								/>
								<Stack.Screen
									name="StudySection"
									component={StudySection}
								/>
								<Stack.Screen
									name="StudyForm"
									component={StudyForm}
								/>
								<Stack.Screen
									name="StudyDisclaimer"
									component={CommonStudyDisclaimer}
								/>
								{/** Treatments routes */}
								<Stack.Screen
									name="Treatments"
									component={Treatments}
								></Stack.Screen>
								<Stack.Screen
									name="AddMedication"
									component={AddMedication}
								/>
								<Stack.Screen
									name="AddTreatments"
									component={AddTreatments}
								/>
								<Stack.Screen name="Drugs" component={Drugs} />
								<Stack.Screen
									name="MedicationHistory"
									component={MedicationHistory}
								/>
								<Stack.Screen
									name="EditMedication"
									component={EditMedication}
								/>
								<Stack.Screen
									name="ReminderView"
									component={ReminderView}
								/>
								<Stack.Screen
									name="AddRescueMedication"
									component={AddRescueMedication}
								/>
								<Stack.Screen
									name="DataSharingRequest"
									component={DataSharing}
								/>
								<Stack.Screen
									name="AddEmergencyMedication"
									component={AddEmergencyMedication}
								/>
								<Stack.Screen
									name="SelectRescueMedication"
									component={SelectRescueMedication}
								/>
								{/** Headache types routes */}
								<Stack.Screen
									name="AddHeadacheTypes"
									component={AddHeadacheTypes}
								/>
								<Stack.Screen
									name="HeadacheTypesList"
									component={HeadacheTypesList}
								/>

								<Stack.Screen
									name="MoreGraphs"
									component={MoreGraphs}
								/>
							</>
						) : (
							<>
								<Stack.Screen
									name="Loading"
									component={Loading}
								/>
							</>
						)}
					</Stack.Navigator>
				</CommonErrorBoundary>
			</NavigationContainer>
		);
};

export default App;
